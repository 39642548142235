:root {
    --CNG: #ca2564;
    --CNG-Grey: #484848;
    --CNG-Lt-Grey: #eaeaea;
}

body {
    background-color: white;
}

html {
    scroll-behavior: smooth;
}

.row {
    width: 100%;
}

.btn.btn-primary {
    background-color: var(--CNG);
    border-color: var(--CNG);
    font-size: 12pt;
    text-transform: none;
    border-radius: 20px;
    box-shadow: none;
}

.btn-raised {
    color: black;
    background-color: var(--CNG-Lt-Grey);
}

.btn.btn-primary:hover {
    color: var(--CNG);
    background-color: var(--CNG-Lt-Grey);
    border-color: var(--CNG-Lt-Grey);
    box-shadow: 0 14px 26px -12px rgb(202 37 100 / 42%), 
                0 4px 23px 0px rgb(0 0 0 / 12%), 
                0 8px 10px -5px rgb(202 37 100 / 20%);
}

.btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:hover {
    color: var(--CNG);
    background-color: var(--CNG-Lt-Grey);
    border-color: var(--CNG-Lt-Grey);
    box-shadow: 0 14px 26px -12px rgb(202 37 100 / 42%), 
                0 4px 23px 0px rgb(0 0 0 / 12%), 
                0 8px 10px -5px rgb(202 37 100 / 20%);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    color: var(--CNG);
    background-color: var(--CNG-Lt-Grey);
    border-color: var(--CNG-Lt-Grey);
}

hr {
    color: var(--CNG);
    width: 24px;
    border-top: 1px solid var(--CNG);
    opacity: 1;
}

h2 {
    font-size: 27pt;
    line-height: 38pt;
    font-weight: 600;
    color: var(--CNG-Grey);
}

h2.title{
    margin-top:0;
    padding-top:80px;
}

h3 {
    font-size: 25pt;
    line-height: 30.4pt;
    font-weight: 500;
}

h5 {
    font-weight: 400;
    font-size: 14.6pt;
}

h6 {
    font-size: 20pt;
    line-height: 24pt;
    text-transform: none;
    font-weight: 300;
    color: var(--CNG)
}

p {
    font-family: nunito;
    font-weight: 400;
    font-size: 11.3pt;
}

.linker {
    position: relative;
    height: 0px;
    bottom: 120px;
}

/*-------------------------NAVBAR-------------------------*/
.navbar {
padding: 0.1rem 0;
}

.navbar .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    text-transform: none;
    padding: 0.9375rem 1.4rem;
    color: #555;
}

.navbar-transparent .navbar-nav .nav-item .nav-link {
    color: #ffffff;
    text-align: center;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
    text-decoration: underline;
}

.navbar-brand img { height: 100%;}
.navbar .navbar-brand { height: 63px;}

.navbar-toggler:focus {
box-shadow: none;
}

@media (max-width: 991px) {
    .navbar-collapse .navbar-nav .nav-item .nav-link {
        color: #555 !important;
    }
}

/*-------------------------PAGE HEADER-------------------------*/

.page-header {
    height: max(660px, 61vh);
}

.header-filter::before {
    background: rgba(0, 0, 0, 0.7);
}

.page-header-img {
    background-image: url(../images/webp/bg.webp);
}

.page-header-img-quote {
    background-image: url(../images/webp/bgQuote.webp);
}

.page-header-img-career {
    background-image: url(../images/webp/bgCareers.webp);
}

h1.title {
    font-size: 37pt;
    line-height: 39pt;
    font-weight: 400;
    margin-bottom:35px;
}

.page-header h4{
    font-size: 25.5pt;
    line-height: 28pt;
}

#wave {
    position: relative;
    bottom: max(6.5rem, 85px);
    z-index: 150;
}

@media (max-width: 976px) {
    #wave {
        bottom: max(4rem, 65px);
    }
}

@media (max-width: 813px) {
    #wave {
        position: relative;
        bottom: max(3rem, 45px);
    }
}

.main {
    z-index: 200;
    margin-top: -15%;
}

/*-------------------------PRODUCTS-------------------------*/

#ProductsServices, #quoteSection  {
margin-top: -65px;
}

.ProductPts {
    margin-left: -10px;
}

.ProductPts li {
    font-family: Nunito;
    font-weight: 400; /*Regular*/
    font-size: 16pt;
    line-height: 30pt;
}

.productSpacer {
    height: 120px;
}

.mid-page-banner1 {
    background-image: url(../images/webp/midpgbanner.webp);
}

.mid-page-banner2 {
    background-image: url(../images/webp/midpgbanner2.webp);
}

.terms-pgs-banner {
    background-image: url(../images/webp/bg-terms.webp)
}

.careers-listing-banner {
    background-image: url(../images/webp/bgCareersListing.webp)
}

.midBanner {
    background-position: center;
    background-size: cover;
    height: 625px;
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
}

@media (max-width: 750px) {
    .midBanner {
        height: 300px;
    }
}

#midBannerImg {
    object-fit: cover;
    padding: 0;
    align-self: center;
}

.IconImage {
    height: 72px;
}

/*-------------------------FOOTER-------------------------*/

#FirstFooter svg:hover {
    color: #ca2564;
    fill: var(--CNG);
}

#footerLogo {
    max-height: 48px;
}

footer svg {
    height: 20px;
    fill: white;
}

footer .btn.btn-primary {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: none;
}

#footerCont {
    background-color: #212121;
    color: #eaeaea;
}

footer {
    padding: 0.9375rem 0;
    text-align: left!important;
}

footer .row {
    margin-right: 0;
    margin-left: 0;
}

#QuickLinks .nav-link {
    color: var(--CNG-Lt-Grey) !important
}

#QuickLinks .nav-link:hover {
    color: var(--CNG) !important;
}

#FooterSubmit {
    border-radius: 2px;
}

/*-------------------------QUOTE PAGE SPECIFIC-------------------------*/

#emailPhone svg {
    height: 30px;
    fill: var(--CNG)
}

#emailPhone h3 {
    font-size: 20pt;
    font-weight: 400;
}

#newsletter1 {
    border: none;
}

input, textarea {
    border: solid 3px var(--CNG);
    border-radius: 10px;
    background-color: white;
    padding: 15px;
}

input::placeholder, textarea::placeholder {
    font-weight: 600;
    font-size: 12pt;
}

textarea {
    resize: vertical !important;
    overflow: auto;
}

/*-------------------------CAREERS SPECIFIC-------------------------*/

.job-card h4 {
    font-size:  22pt;
    font-weight: 400;
}

.job-card li {
    font-size: 13pt;
    line-height: 21pt
}

.job-listing-card {
    border: 4px solid var(--CNG-Lt-Grey);
    border-radius: 10px;
    align-items: center;
    padding: 5px 20px;
}

.job-listing-card h3{
    font-size: 20pt;
    line-height: 20pt;
}

.job-listing-card h4{
    font-size: 16pt;
    line-height: 16pt;
}

.partnerLogo {
    max-height: 150px;
}

@media (max-width: 429px) {
    .page-header h1 {
        font-size: 34pt;
    }
    .page-header h4 {
        font-size: 22.5pt;
        line-height: 27pt;
    }
    .partnerLogo {
        padding: 20px 0;
    }
}