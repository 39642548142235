

h5.description * {font-size: inherit}


@media (max-width: 768px) {
    h1,h2,h3 {
        zoom: 75%;
    }
}

.carousel-caption h4{
    font-size: 1.25rem;
    line-height: 1.5em;
    text-shadow: 0 1px 2px rgba(0,0,0,.9);
    background-color: #b6524a;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0px grey;
}

.tab-pane {
    display: none;
}
.tab-pane.active {
    display: block;
    width:100%;
}

.modal-open {
    overflow:hidden;
    overflow-y:scroll;
    padding-right:0 !important;
}

.and-up{
    color: #555555;
    font-weight: bold;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 10px;
    padding: 0;
    margin: 0;
}

.tim-row {
    margin-bottom: 20px;
}

.presentation-page {
    overflow-x: hidden;
}

.title {
    margin-top: 30px;
    margin-bottom: 40px;
    min-height: 32px;
    /*position: relative;
      display: inline-block;
      width: 100%;*/
}

.title.text-center {
    margin-bottom: 50px;
}

.tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
}

.tim-typo .tim-note {
    bottom: 5px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}

.tim-row {
    padding-top: 50px;
}

.tim-row h3 {
    margin-top: 0;
}

#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
    margin-bottom: 0;
}

.switch {
    margin-right: 20px;
}

#navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
}

.space {
    height: 130px;
    display: block;
}

.space-110 {
    height: 110px;
    display: block;
}

.space-50 {
    height: 50px;
    display: block;
}

.space-70 {
    height: 70px;
    display: block;
}

.navigation-example .img-src {
    background-attachment: scroll;
}

.tim-container .noUi-horizontal {
    margin: 30px 0;
}

.navigation-example {
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    min-height: 740px;
}

#notifications {
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    position: relative;
}

#notifications .alert-danger {
    margin-bottom: 0px;
}

.tim-note {
    text-transform: capitalize;
}

#buttons .btn {
    margin: 0 0px 15px;
}

.space-100 {
    height: 100px;
    display: block;
    width: 100%;
}

.be-social {
    padding-bottom: 20px;
    /*     border-bottom: 1px solid #aaa; */
    margin: 0 auto 40px;
}

.txt-white {
    color: #FFFFFF;
}

.txt-gray {
    color: #ddd !important;
}


.parallax {
    width: 100%;
    height: 570px;

    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.logo-container .logo {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
}

.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.parallax .parallax-image img {
    width: 100%;
}

.social-buttons-demo .btn {
    /*margin-right: 5px;
      margin-bottom: 7px;*/
    margin: 10px 5px 7px 1px;
}

.img-container {
    width: 100%;
    overflow: hidden;
}

.img-container img {
    width: 100%;
}

.lightbox img {
    width: 100%;
}

.lightbox .modal-content {
    overflow: hidden;
}

.lightbox .modal-body {
    padding: 0;
}

@media screen and (min-width: 991px) {
    .lightbox .modal-dialog {
        width: 960px;
    }
}

@media (max-width: 991px) {
    .fixed-section.affix {
        position: relative;
        margin-bottom: 100px;
    }
}

@media (max-width: 768px) {

    .btn,
    .btn-morphing {
        margin-bottom: 10px;
    }

    .parallax .motto {
        top: 170px;
        margin-top: 0;
        font-size: 60px;
        width: 270px;
    }
}

/*       Loading dots  */

/*      transitions */
.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}


#images h4 {
    margin-bottom: 30px;
}

#javascriptComponents {
    padding-bottom: 0;
}

#javascriptComponents .btn-raised {
    margin: 10px 0px;
}


/*      layer animation          */

.layers-container {
    display: block;
    margin-top: 50px;
    position: relative;
}

.layers-container img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    text-align: center;
}

.section-black {
    background-color: #333;
}

.animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
    color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.sharing-area {
    margin-top: 80px;
}

.sharing-area .btn {
    margin: 15px 4px 0;
    color: #FFFFFF;
}

.sharing-area .btn i {
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

#navbar {
    margin-bottom: -20px;
}

.sharing-area .btn-twitter {
    background-color: #55acee;
}

.sharing-area .btn-twitter:active:focus {
    background-color: #55acee;
}

.sharing-area .btn-facebook {
    background-color: #3b5998;
}

.sharing-area .btn-facebook:active:focus {
    background-color: #3b5998;
}

.sharing-area .btn-google-plus {
    background-color: #dd4b39;
}

.sharing-area .btn-google-plus:active:focus {
    background-color: #dd4b39;
}

.sharing-area .btn-github {
    background-color: #333333;
}

.sharing-area .btn-github:active:focus {
    background-color: #333333;

}

.section-thin,
.section-notifications {
    padding: 0;
}

.section-navbars {
    padding-top: 0;
}

#navbar .navbar {
    border-radius: 0;
}

.section-tabs {
    background: #EEEEEE;
}

.section-pagination {
    padding-bottom: 0;
}

.section-download h4 {
    margin-bottom: 50px;
}

.section-examples a {
    text-decoration: none;
}

.section-examples h5 {
    margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
    height: 400px;
    padding-top: 100px;
    background-size: cover;
    background-position: center center;
}

.components-page .title,
.tutorial-page .title {
    color: #FFFFFF;
}

@media (max-width: 830px) {
    .main-raised {
        margin-left: 10px;
        margin-right: 10px;
    }
}

/*@media all and (min-width: 1200px){
    #modal-row [class*="col-"]:nth-child(1),
    #modal-row [class*="col-"]:nth-child(4),
    #javascriptComponents [class*="col-"]:nth-child(2),
    #javascriptComponents [class*="col-"]:nth-child(5){
        padding-right: 3px;
    }

    #modal-row [class*="col-"]:nth-child(2),
    #javascriptComponents [class*="col-"]:nth-child(3){
        padding-right: 3px;
        padding-left: 3px;
    }

    #modal-row [class*="col-"]:nth-child(3),
    #modal-row [class*="col-"]:nth-child(5),
    #javascriptComponents [class*="col-"]:nth-child(4),
    #javascriptComponents [class*="col-"]:nth-child(6){
        padding-left: 3px;
    }
}*/

.components-page .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.components-page .page-header:after {
    background: rgba(132, 13, 121, 0.88);
    background: linear-gradient(45deg, rgba(132, 13, 121, 0.88) 0%, rgba(208, 44, 180, 0.31) 100%);
    background: -moz-linear-gradient(135deg, rgba(132, 13, 121, 0.88) 0%, rgba(208, 44, 180, 0.31) 100%);
    background: -webkit-linear-gradient(135deg, rgba(132, 13, 121, 0.88) 0%, rgba(208, 44, 180, 0.31) 100%);
}

.components-page .page-header.header-filter:before {
    display: none;
}

.components-page .page-header .title-container {
    position: relative;
    z-index: 10;
}

.components-page .footer .container {
    padding-top: 0;
}

.components-page .footer .btn i {
    color: #fff;
}

.components-page .page-header {
    height: 100vh !important;
    min-height: auto !important;
}
.page-header{
    overflow: hidden;
}

/*  PRESENTION PAGE   */

/* Section Components */

.section-components .title {
    margin-top: 60px;
    margin-bottom: 5px;
}

.section-components .description {
    margin-top: 35px;
}

.section-components .image-container {
    height: 560px;
    position: relative;
}

.section-components .components-macbook {
    width: auto;
    left: -100px;
    top: 0;
    height: 100%;
    position: absolute;
}

.section-components .table-img,
.section-components .share-btn-img,
.section-components .coloured-card-btn-img,
.section-components .coloured-card-img,
.section-components .social-img,
.section-components .pin-btn-img {
    position: absolute;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.section-components .coloured-card-img {
    left: 90%;
    top: 11%;
    height: 90px;
    border-radius: 2px;
}

.section-components .social-img {
    left: 70%;
    top: 67%;
    height: 40px;

}

.section-components .coloured-card-btn-img {
    left: 35%;
    top: 2%;
    height: 125px;
    border-radius: 6px;
}

.section-components .table-img {
    left: -13%;
    top: 27%;
    height: 175px;
    border-radius: 2px;
}

.section-components .pin-btn-img {
    left: 0%;
    top: 68%;
    height: 25px;
    border-radius: 3px;
}

.section-components .share-btn-img {
    left: 3%;
    top: -3%;
    height: 25px;

}

/* Section Content */

.section-content .section-description {
    margin-top: 100px;
}

.section-content .title {
    margin-bottom: 5px;
}

.section-content .section-description .title+.description {
    margin-top: 0;
}

.section-content .description {
    margin-top: 35px;
}

.section-content .image-container {
    max-width: 900px;
    position: relative;
}

.section-content .image-container .ipad-img {
    width: 100%;
    transition: transform .6s .9s;
    z-index: 2;
}

.section-content .image-container .area-img,
.section-content .image-container .info-img {
    position: absolute;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translate3D(0px, 100px, 0px);
    -webkit-transform: translate3D(0px, 100px, 0px);
}

.section-content .image-container .area-img {
    top: 10%;
    left: 60%;
    height: 300px;
    width: 250px;
    z-index: 2;
    -webkit-transition: all 1s ease-out 0s;
    -moz-transition: all 1s ease-out 0s;
    -o-transition: all 1s ease-out 0s;
    -ms-transition: all 1s ease-out 0s;
    transition: all 1s ease-out 0s;
}

.section-content .image-container .info-img {
    top: 50%;
    left: 0%;
    height: 200px;
    width: 300px;
    -webkit-transition: all 1s ease-out 0s;
    -moz-transition: all 1s ease-out 0s;
    -o-transition: all 1s ease-out 0s;
    -ms-transition: all 1s ease-out 0s;
    transition: all 1s ease-out 0s;
}

.animated {
    transform: translate3D(0px, 0px, 0px) !important;
    -webkit-transform: translate3D(0px, 0px, 0px) !important;
    opacity: 1 !important;
}


/*  animated images  */

@-webkit-keyframes Floatingy {
    from {
        -webkit-transform: translate(0, 0px);
    }

    65% {
        -webkit-transform: translate(50px, 0);
    }

    to {
        -webkit-transform: translate(0, -0px);
    }
}

@-moz-keyframes Floatingy {
    from {
        -moz-transform: translate(0, 0px);
    }

    65% {
        -moz-transform: translate(50px, 0);
    }

    to {
        -moz-transform: translate(0, -0px);
    }
}

@-webkit-keyframes Floatingx {
    from {
        -webkit-transform: translate(50px, 0px);
    }

    65% {
        -webkit-transform: translate(0, 0);
    }

    to {
        -webkit-transform: translate(50px, -0px);
    }
}

@-moz-keyframes Floatingx {
    from {
        -moz-transform: translate(50px, 0px);
    }

    65% {
        -moz-transform: translate(0, 0);
    }

    to {
        -moz-transform: translate(50px, -0px);
    }
}

.table-img,
.coloured-card-img,
.coloured-card-btn-img,
.pin-btn-img,
.share-btn-img,
.social-img {
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    float: left;
}

.table-img {
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
}

.coloured-card-img {
    -webkit-animation-duration: 10s;
    -moz-animation-duration: 10s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
}

.coloured-card-btn-img {
    -webkit-animation-duration: 18s;
    -moz-animation-duration: 18s;
}

.pin-btn-img {
    -webkit-animation-duration: 15s;
    -moz-animation-duration: 15s;
}

.share-btn-img {
    -webkit-animation-duration: 11s;
    -moz-animation-duration: 11s;
}

.social-img {
    -webkit-animation-duration: 16s;
    -moz-animation-duration: 16s;
    -webkit-animation-name: Floatingx;
    -moz-animation-name: Floatingx;
}

/* Presentation Page OnScroll Animation */

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

.animated.hinge {
    animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
    animation-duration: .75s;
}

@keyframes bounce {

    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        transform: translate3d(0, -4px, 0);
    }
}

.bounce {
    animation-name: bounce;
    transform-origin: center bottom;
}

@keyframes flash {

    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.flash {
    animation-name: flash;
}
.grecaptcha-badge {
    visibility: hidden;
}

img {max-width: 100%;}

#footerText{

    padding: 10px;
    font-size: 12px;
    font-weight: 100;
    text-align: center;
    font-style: italic;
}

.bmd-form-group:not(.is-filled) .bmd-label-floating {
    left: 50%;
    transform: translateX(-50%);
}

.contact-form{ padding: 5px;}

.account-deletion-form{ padding: 5px;}

.modal-full-width{
    /*
    max-width: 92vw;
    max-height: 92vh;
    margin: 0 auto;

     */
}

.modal-full-width .modal-body{
    padding: 0;
}

.modal-full-width button.close{

    margin-top: 10px;
    margin-bottom: -35px;
    background: white;
    margin-right: 13px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 3px;
}

.modal-full-width .imagepreview{
    width: 100%; border-radius: 6px;
}

.modal{background: #00000080;}

.gallery-image{width: 60%; cursor: pointer}
#curbngo-logo {height: 18px;}

.carousel-inner {
    margin-left: auto;
    margin-right: auto;
    max-height: 650px;
    max-width: 1000px;
}

.card.card-raised.card-carousel {
    margin-left: auto;
    margin-right: auto;
    max-height: 650px;
    max-width: 1000px;
}

.main.main-raised {overflow-x: hidden;}

.dropdown-menu {
    max-width: 100%;
}
.dropdown-menu .dropdown-item {
    white-space: normal;
}

